import DetailedPreviewCard from 'components/ContentCards/DetailedPreviewCard'
import type { DetailedPreviewCardProps } from 'components/ContentCards/DetailedPreviewCard/DetailedPreviewCard'

type CarouselCardProps = Omit<DetailedPreviewCardProps, 'image'>

/**
 *
 * @returns A preview card suitable for use in a carousel. It has an title, subtitle and meta fields, but no image.
 * https://www.figma.com/file/HqUnQfZ4SSCHHAZuI0Ioco/UI-Content-Expansion?type=design&node-id=726-712&mode=design&t=PrZCrneFbm6Fpteq-0
 */
const CarouselCard = ({
    title,
    subtitle,
    url,
    author,
    metaText,
    color,
    tags: Tags,
    isLocked,
    contentTypePublicName,
    bookmarkButton,
    onClick,
}: CarouselCardProps) => {
    return (
        <DetailedPreviewCard
            title={title}
            subtitle={subtitle}
            url={url}
            author={author}
            titleMaxLines={4}
            subtitleMaxLines={5}
            metaText={metaText}
            color={color}
            tags={Tags}
            isLocked={isLocked}
            image={null}
            contentTypePublicName={contentTypePublicName}
            bookmarkButton={bookmarkButton}
            onClick={onClick}
        />
    )
}

export default CarouselCard
