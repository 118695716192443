import { graphql, useFragment } from 'react-relay'

import type { useIsAuthenticated_viewer$key } from '__generated__/useIsAuthenticated_viewer.graphql'

/**
 * Hook to check if the user is authenticated using relay data.
 * This fragment should be spread in the query of all page components.
 */
export const useIsAuthenticated = (viewerKey: useIsAuthenticated_viewer$key) => {
    const viewer = useFragment(useIsAuthenticatedFragment, viewerKey)
    return !!viewer?.me || viewer?.me?.authorization.roles.isAnonymous === false
}

const useIsAuthenticatedFragment = graphql`
    fragment useIsAuthenticated_viewer on Viewer {
        me {
            authorization {
                roles {
                    hasPremiumSubscription
                    isAnonymous
                }
            }
        }
    }
`
