/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type useIsAuthenticated_viewer = {
    readonly me: {
        readonly authorization: {
            readonly roles: {
                readonly hasPremiumSubscription: boolean;
                readonly isAnonymous: boolean;
            };
        };
    } | null;
    readonly " $refType": "useIsAuthenticated_viewer";
};
export type useIsAuthenticated_viewer$data = useIsAuthenticated_viewer;
export type useIsAuthenticated_viewer$key = {
    readonly " $data"?: useIsAuthenticated_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"useIsAuthenticated_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "useIsAuthenticated_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Authorization",
          "kind": "LinkedField",
          "name": "authorization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleFlags",
              "kind": "LinkedField",
              "name": "roles",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPremiumSubscription",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "isAnonymous",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '9162ad0cf9d60f4b99890def3912284d';
export default node;
