/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from "relay-runtime";

import { FragmentRefs } from "relay-runtime";
export type home_viewer = {
    readonly me: {
        readonly authorization: {
            readonly roles: {
                readonly hasPremiumSubscription: boolean;
            };
        };
    } | null;
    readonly authorization: {
        readonly " $fragmentRefs": FragmentRefs<"authorization_authorization">;
    };
    readonly content: {
        readonly " $fragmentRefs": FragmentRefs<"authorization_content">;
    };
    readonly webLayouts: {
        readonly homePage: {
            readonly mainLayoutBlocks: {
                readonly edges: ReadonlyArray<{
                    readonly node: {
                        readonly " $fragmentRefs": FragmentRefs<"ContentPieceSection_contentPieceSelection">;
                    };
                }>;
                readonly " $fragmentRefs": FragmentRefs<"MainLayoutBlocksFragment_mainLayoutBlocks">;
            };
        } | null;
    };
    readonly " $fragmentRefs": FragmentRefs<"DefaultLayout_viewer" | "MainLayoutBlocks_viewer" | "TodayIn10Min_viewer">;
    readonly " $refType": "home_viewer";
};
export type home_viewer$data = home_viewer;
export type home_viewer$key = {
    readonly " $data"?: home_viewer$data | undefined;
    readonly " $fragmentRefs": FragmentRefs<"home_viewer">;
};



const node: ReaderFragment = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "encryptedEmail"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "home_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "me",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Authorization",
          "kind": "LinkedField",
          "name": "authorization",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "RoleFlags",
              "kind": "LinkedField",
              "name": "roles",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasPremiumSubscription",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Authorization",
      "kind": "LinkedField",
      "name": "authorization",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "authorization_authorization"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "encryptedEmail",
          "variableName": "encryptedEmail"
        }
      ],
      "concreteType": "Content",
      "kind": "LinkedField",
      "name": "content",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "authorization_content"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "WebLayouts",
      "kind": "LinkedField",
      "name": "webLayouts",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "HomePage",
          "kind": "LinkedField",
          "name": "homePage",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectionRowBlockConnection",
              "kind": "LinkedField",
              "name": "mainLayoutBlocks",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "SelectionRowBlockNonNullEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "ContentPieceSection_contentPieceSelection"
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "MainLayoutBlocksFragment_mainLayoutBlocks"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "DefaultLayout_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "MainLayoutBlocks_viewer"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TodayIn10Min_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
(node as any).hash = '0113b7dc57c9ef842593e3b32ffddc3f';
export default node;
