import { graphql, useFragment } from 'react-relay'

import type { authorization_authorization$key } from '__generated__/authorization_authorization.graphql'
import type { authorization_content$key } from '__generated__/authorization_content.graphql'
import type { TodayIn10Min_viewer$key } from '__generated__/TodayIn10Min_viewer.graphql'
import Carousel from 'components/Carousel'
import CarouselCard from 'components/ContentCards/CarouselCard/CarouselCard'
import CarouselHighlightCard from 'components/ContentCards/CarouselHighlightCard/CarouselHighlightCard'
import ContentPieceCardWrapper from 'components/ContentCards/DataWrappers/Relay/ContentPieceCardWrapper'
import Image from 'components/Image'
import Box from 'components/Primitives/Box'
import FullWidthBackground from 'containers/FullWidthBackground'
import {
    CAROUSEL_CONTENT_CARD_HEIGHT,
    CAROUSEL_CONTENT_CARD_WIDTH,
} from 'modules/ContentSection/CarouselSection'
import LargeHeader from 'modules/ContentSection/LargeHeader'
import type { ColorName } from 'theme/index'
import { palette } from 'theme/index'

type Props = {
    viewer: TodayIn10Min_viewer$key
    authorization: authorization_authorization$key
    contentAuthorization: authorization_content$key
}

const TodayIn10Min = ({ viewer: viewerKey, authorization, contentAuthorization }: Props) => {
    const viewer = useFragment(TodayIn10MinFragment, viewerKey)

    // We should always have content pieces for these slots in practice, but filtering out undefined just to make sure
    const contentPieceList = [
        viewer.insightOrResearch.edges[0]?.node,
        viewer.quickTake.edges[0]?.node,
        viewer.brief.edges[0]?.node,
        viewer.expandedNews.edges[0]?.node,
        viewer.expandedNews.edges[1]?.node,
    ].filter(item => item !== undefined)

    const cardColor = palette.neutral[0] as ColorName
    const texturedBanner = '/images/textured.jpg'

    return (
        <FullWidthBackground backgroundColor="neutral.900">
            <Box paddingY={3}>
                <Image alt="texture" src={texturedBanner} fill priority objectFit="cover" />
                <Box position={'relative'} paddingX={[1, 1, 2, 5]}>
                    <LargeHeader
                        title="Today in 10 minutes"
                        subtitle="What you need to know today"
                        fontColor="neutral.0"
                    />

                    <Carousel
                        backgroundColor="neutral.900"
                        ariaLabel="Today in 10 minutes carousel content"
                        itemWidth={CAROUSEL_CONTENT_CARD_WIDTH}
                        itemHeight={CAROUSEL_CONTENT_CARD_HEIGHT}
                        options={{ active: true }}
                    >
                        {contentPieceList &&
                            contentPieceList.map((contentPiece, index) => {
                                if (index === 0)
                                    return (
                                        <ContentPieceCardWrapper
                                            CardComponent={CarouselHighlightCard}
                                            key={contentPiece.id}
                                            contentPiece={contentPiece}
                                            authorization={authorization}
                                            contentAuthorization={contentAuthorization}
                                            priorityImageLoad={true}
                                            color={cardColor}
                                        />
                                    )

                                return (
                                    <ContentPieceCardWrapper
                                        CardComponent={CarouselCard}
                                        key={contentPiece.id}
                                        contentPiece={contentPiece}
                                        authorization={authorization}
                                        contentAuthorization={contentAuthorization}
                                        color={cardColor}
                                    />
                                )
                            })}
                    </Carousel>
                </Box>
            </Box>
        </FullWidthBackground>
    )
}

const TodayIn10MinFragment = graphql`
    fragment TodayIn10Min_viewer on Viewer {
        insightOrResearch: contentPieces(contentPieceTypes: ["INSIGHT", "RESEARCH"], first: 1) {
            edges {
                node {
                    id
                    ...ContentPieceCardWrapper_contentPiece
                }
            }
        }
        quickTake: contentPieces(contentPieceType: "QUICK_TAKE", first: 1) {
            edges {
                node {
                    id
                    ...ContentPieceCardWrapper_contentPiece
                }
            }
        }
        brief: contentPieces(contentPieceTypes: ["DAILY_BRIEF", "WEEKLY_BRIEF"], first: 1) {
            edges {
                node {
                    id
                    ...ContentPieceCardWrapper_contentPiece
                }
            }
        }
        expandedNews: personalisedContentPieces(first: 2) {
            edges {
                node {
                    id
                    ...ContentPieceCardWrapper_contentPiece
                }
            }
        }
    }
`
export default TodayIn10Min
